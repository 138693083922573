<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList } from '@/utils/textFile'

export default {
  name: 'whiteList',
  data() {
    return {
      ...api.command.getState(),
      formTyps: [
        {
          text: '农家商城',
          value: '1',
        },
        {
          text: '农家乐',
          value: '2',
        },
        // {
        //   text: '民宿',
        //   value: '3',
        // },
        {
          text: '农事体验',
          value: '4',
        },
        // {
        //   text: '垂钓',
        //   value: '5',
        // },
        {
          text: '景点门票',
          value: '12',
        },
        {
          text: '酒店民宿',
          value: '13',
        },
        {
          text: '组合套餐',
          value: '14',
        },
        {
          text: '船票',
          value: '15',
        },
        {
          text: '车票',
          value: '16',
        },
      ],
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/whiteListOrder/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'productName',
          placeholder: '请输入名称',
        },
        {
          name: '用户',
          type: 'input',
          key: 'buyName',
          placeholder: '请输入用户',
        },
        {
          name: '联系电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入联系电话',
        },
      ]
    },
    // 获取选中发票详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/whiteListOrder/detail?id=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record,
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/agr/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'productName',
          title: '出售商品',
        },
        {
          dataIndex: 'fromType',
          title: '商品类型',
          //   type: 'badge',
          customRender(data) {
            if (data == 12) {
              return '景点门票'
            } else if (data == 13) {
              return '酒店民宿'
            } else if (data == 14) {
              return '组合套餐'
            }
          },
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'buyName',
          title: '用户',
          align: 'left',
        },
        {
          dataIndex: 'buyPhone',
          title: '电话',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',

          align: 'center',
        },
        {
          dataIndex: 'createTime',
          title: '赠送时间',

          align: 'center',
        },
        {
          dataIndex: 'createByName',
          title: '创建人',

          align: 'center',
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '详情',
                onClick: () => {
                  //   this.$router.push({ path: '/commodityManagement/whiteListDetail', query: { id: records.id } })
                  this.onRowSelect(records)
                },
              },
              {
                name: '删除',
                type: 'pop',
                dispaly: true,
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/whiteListOrder/delete?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/whiteListDetail'),
        },
      ]
    },
    onRowSelect(records) {
      const ext = [
        // 基本信息
        orderConfig.baseWhite,

        orderConfig.userWhite,
        orderConfig.useRecordWhite,
        orderConfig.recordWhite,
      ]
      this.getDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '订单信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        // onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
